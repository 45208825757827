<template>
  <q-page>
    <div class="text-h4">Bienvenido a las configuraciones</div>
  </q-page>
</template>
<script>
  export default {
    name: "homeConfiguraciones",
    data() {
      return {}
    }
  }
</script>